<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h2 class="mt-5">Subscribe</h2>
      <p>Thank you for your interest, you can leave me your email to receive updates about my work.</p>
      <p>I'm planning to release a new set of video tutorials for the language Go.</p>
      <p>Be one of the first to be notified when it will be out !</p>
      <p>I promise that:</p>
      <ul>
        <li>Your email address will not be spammed (I'm too lazy to send many emails) :)</li>
        <li>I will never sell/give your address to others</li>
      </ul>
      <NewsletterInputLight></NewsletterInputLight>

    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import NewsletterInputLight from "@/components/NewsletterInputLight";

export default {
  name: "Subscribe",
  components: {NavBar, Footer,NewsletterInputLight}
}
</script>

<style scoped>

</style>